import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
  <Link to="/">
    <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" style={{ width: '50px', height: '50px' }} /> {/* Adjust width and height as needed */}
  </Link>
</div>
      <div className="navbar-links-container">
        <ul className="navbar-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Me</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contacts">Contacts</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
